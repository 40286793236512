import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Bienvenue à Nautical Livraison de nourriture
			</title>
			<meta name={"description"} content={"Les saveurs à votre porte - Nautical Delivery, votre voyage culinaire vous attend"} />
			<meta property={"og:title"} content={"Bienvenue à Nautical Livraison de nourriture"} />
			<meta property={"og:description"} content={"Les saveurs à votre porte - Nautical Delivery, votre voyage culinaire vous attend"} />
			<meta property={"og:image"} content={"https://antalyaazure.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://antalyaazure.com/images/decorated-car.svg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://antalyaazure.com/images/decorated-car.svg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://antalyaazure.com/images/decorated-car.svg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://antalyaazure.com/images/decorated-car.svg"} />
			<link rel={"apple-touch-startup-image"} href={"https://antalyaazure.com/images/decorated-car.svg"} />
			<meta name={"msapplication-TileImage"} content={"https://antalyaazure.com/images/decorated-car.svg"} />
		</Helmet>
		<Components.Header />
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});